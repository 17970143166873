(function ($) {
  jQuery(document).ready(function () {
    $(".carousel-categories-slick").slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      autoplaySpeed: 4000,
      prevArrow: $(".button-prev"),
      nextArrow: $(".button-next"),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".carousel-categories-slick").on(
      "beforeChange",
      function (event, slick, currentSlide, nextSlide) {
        var calc = (nextSlide / (slick.slideCount - 1)) * 100;

        $(".carousel-categories-section .progress")
          .css("background-size", calc + "% 100%")
          .attr("aria-valuenow", calc);

        $(".carousel-categories-section .slider__label").text(
          calc + "% completed"
        );
      }
    );
  });
})(jQuery);
